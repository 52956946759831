import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { ContactForm } from '../forms'
import { useFirebase } from "gatsby-plugin-firebase"

import {
  FaMailBulk,
} from 'react-icons/fa';

const ContactPageTemplate = ({ title, subtitle, meta_title, meta_description }) => {
  useFirebase(firebase => {
    firebase
      .analytics()
      .logEvent("contact_page")
  }, [])

  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>

    <section className='hero is-primary is-bold'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-vcentered'>

            <div className='column is-12-mobile is-6' >
              <div className='section'>
                <h1 className='title has-text-weight-semibold is-size-3'>{title}</h1>
                <h2 className='is-size-5'>{subtitle}</h2>
              </div>
            </div>

            <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
              <figure className="image is4by3 mt-2">
                <FaMailBulk size={340} color={'#f7e151'} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section'>
      <div className='container'>
        <ContactForm />
      </div>
    </section>
  </div>
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
